import React from "react";
import { Box, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ChangePasswordForm from "../auth/ChangePassword.component";
import ProfileCard from "./user/ProfileCard.component";
import SettingsTabs from "./user/Settings.component";

const UserSettings = ({ user, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Box
        sx={{
          minWidth: { xs: "300px", sm: "360px", md: "400px" },
          maxWidth: 600,
        }}>
        <Paper variant="outlined">
          <Grid item sx={{ padding: 1 }}>
            <Typography variant="h6" component="div">
              {t("user-settings")}
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <ProfileCard user={user} />
          </Grid>
          <Grid item>
            <SettingsTabs
              tabs={[
                {
                  key: 0,
                  name: `${t("user-settings-security")}`,
                  component: ChangePasswordForm,
                },
              ]}
            />
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};

export default UserSettings;
