import axios from "axios";
import { TOKEN_KEY } from "./auth";
import { getItem, removeItem } from "./storage";

// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_API_GW || "http://localhost:5001";

const api = axios.create({
  baseURL,
});

// Interceptor to attach the authentication token to each request
api.interceptors.request.use((config) => {
  // Ignore the login request
  if (config.url === "/login") {
    return config;
  }
  const token = getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `Bearer ${token.replace(/^"(.*)"$/, "$1")}`;
  }
  return config;
});

// Interceptor to handle the authentication token expiration
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      removeItem(TOKEN_KEY);
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default api;
