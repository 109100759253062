import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Brightness2Icon from "@mui/icons-material/Brightness2";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import {
  Badge,
  Box,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Navbar } from "./NavbarElements.component";
import { Copyright } from "./Copyright.component";
import { AppBar, Drawer } from "./AppBar.component";
import { VersionDisplay } from "./Version.component";
import { setUser } from "../../utils/reducers/userReducer";
import api from "../../utils/api";
import LanguageSwitcher from "../common/LanguageSwither.component";
import UserSettings from "./UserSettings.component";

import {
  lightTheme,
  darkTheme,
  darkThemeGrayscale,
} from "../common/Themes.component";

export default function Base({ onLogout, title, children }) {
  const [open, setOpen] = React.useState(false);
  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const [userSettingsOpen, setUserSettingsOpen] = React.useState(false);
  const [theme, setTheme] = React.useState(darkTheme);
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!user) {
      api.get("/user").then((res) => {
        dispatch(setUser(res.data));
      });
    }
  }, [dispatch, user]);

  const handleUserSettingsOpen = () => {
    setUserSettingsOpen(true);
  };

  const handleUserSettingsClose = () => {
    setUserSettingsOpen(false);
  };

  const handleUserMenuOpen = (event) => {
    setUserMenuOpen(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuOpen(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleTheme = () => {
    setTheme(theme.palette.mode === "dark" ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <LanguageSwitcher />
            <IconButton color="inherit" onClick={toggleTheme}>
              <Badge color="secondary">
                {theme.palette.mode === "dark" ? (
                  <WbSunnyIcon />
                ) : (
                  <Brightness2Icon />
                )}
              </Badge>
            </IconButton>
            <Divider sx={{ mx: 1 }} />
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Divider sx={{ mx: 1 }} />
            <IconButton color="inherit" onClick={handleUserMenuOpen}>
              <Badge color="secondary">
                <AccountCircleIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={userMenuOpen}
          open={Boolean(userMenuOpen)}
          onClose={handleUserMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <MenuItem onClick={handleUserSettingsOpen}>
            {t("user-settings")}
          </MenuItem>
          <MenuItem onClick={onLogout}>{t("navbar-logout")}</MenuItem>
        </Menu>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Navbar user={user} />
        </Drawer>
        {userSettingsOpen && (
          <UserSettings
            user={user}
            open={userSettingsOpen}
            onClose={handleUserSettingsClose}
          />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}>
          <Toolbar />
          <Container maxWidth="false" sx={{ paddingTop: "2rem" }}>
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, { theme });
              }
              return child;
            })}
            <Copyright sx={{ pt: 4 }} />
            <VersionDisplay />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
