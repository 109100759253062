/* eslint-disable no-undef */

import { Typography, Box } from "@mui/material";

export const VersionDisplay = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      height: "100%",
    }}>
    <Typography variant="body2" color="text.secondary" align="center">
      {`v${process.env.REACT_APP_VERSION}`}
    </Typography>
  </Box>
);
