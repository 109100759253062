import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Tooltip,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_EditActionButtons,
} from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import api from "../../utils/api";

const CreateNewRowModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  tittle,
  genres,
  authors,
  publishers,
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {}),
  );
  const [genre, setGenre] = useState({ key: 0, value: "" });
  const [author, setAuthor] = useState({ key: 0, value: "" });
  const [publisher, setPublisher] = useState({ key: 0, value: "" });

  const cleanAutocompletes = () => {
    setGenre({ key: 0, value: "" });
    setAuthor({ key: 0, value: "" });
    setPublisher({ key: 0, value: "" });
  };

  const handleSubmit = () => {
    values.genre = genre.value;
    values.author = author.value;
    values.publisher = publisher.value;
    onSubmit(values);
    cleanAutocompletes();
    onClose();
  };

  const handleCancel = () => {
    cleanAutocompletes();
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{tittle ?? "Create New Row"}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              paddingTop: ".5rem",
            }}>
            {columns.map((column) => (
              <>
                {column.accessorKey === "id" ? (
                  <> </>
                ) : column.accessorKey === "genre" ? (
                  <>
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        autoSelect
                        id="genre-autocomplete"
                        options={genres}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField {...params} label={column.header} />
                        )}
                        value={genre}
                        onChange={(event, newValue) => {
                          setGenre(
                            typeof newValue === "string"
                              ? { key: 0, value: newValue }
                              : newValue,
                          );
                        }}
                      />
                    </FormControl>
                  </>
                ) : column.accessorKey === "author" ? (
                  <>
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        autoSelect
                        id="author-autocomplete"
                        options={authors}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField {...params} label={column.header} />
                        )}
                        value={author}
                        onChange={(event, newValue) => {
                          setAuthor(
                            typeof newValue === "string"
                              ? { key: 0, value: newValue }
                              : newValue,
                          );
                        }}
                      />
                    </FormControl>
                  </>
                ) : column.accessorKey === "publisher" ? (
                  <>
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        autoSelect
                        id="publisher-autocomplete"
                        options={publishers}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField {...params} label={column.header} />
                        )}
                        value={publisher}
                        onChange={(event, newValue) => {
                          setPublisher(
                            typeof newValue === "string"
                              ? { key: 0, value: newValue }
                              : newValue,
                          );
                        }}
                      />
                    </FormControl>
                  </>
                ) : (
                  <TextField
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                )}
              </>
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={handleCancel}>{t("tables-cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {t("tables-save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Books = (themeProp) => {
  const [loading, setLoading] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [genres, setGenres] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const theme = themeProp.theme;
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user?.user);

  const columns = useMemo(
    () => [
      {
        header: `${t("tables-id")}`,
        accessorKey: "id",
        size: 10,
        grow: false,
        enableEditing: false,
      },
      {
        header: `${t("tables-title")}`,
        accessorKey: "title",
        minSize: 100,
        size: 110,
        // enableEditing: false,
      },
      {
        header: `${t("tables-genre")}`,
        accessorKey: "genre",
        size: 50,
        // enableEditing: false,
      },
      {
        header: `${t("tables-author")}`,
        accessorKey: "author",
        size: 50,
        // enableEditing: false,
      },
      {
        header: `${t("tables-publisher")}`,
        accessorKey: "publisher",
        size: 30,
        // enableEditing: false,
      },
      {
        header: `${t("tables-location")}`,
        accessorKey: "location",
        size: 20,
        // enableEditing: false,
      },
      {
        header: `${t("tables-comment")}`,
        accessorKey: "comment",
        size: 10,
      },
    ],
    [t],
  );
  const [data, setData] = useState([]);

  // for custom edit row
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {}),
  );
  const [genre, setGenre] = useState({ key: 0, value: "", initial: true });
  const [author, setAuthor] = useState({ key: 0, value: "", initial: true });
  const [publisher, setPublisher] = useState({
    key: 0,
    value: "",
    initial: true,
  });
  const cleanAutocompletes = () => {
    setGenre({ key: 0, value: "", initial: true });
    setAuthor({ key: 0, value: "", initial: true });
    setPublisher({ key: 0, value: "", initial: true });
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAll = () => {
    api
      .get("/books")
      .then((response) => {
        const books = response.data;
        setData(books);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === "jwt expired") {
          enqueueSnackbar(t("session-expired"), { variant: "error" });
        }
        enqueueSnackbar(t("books-get-error"), { variant: "error" });
      });
    api.get("/genres").then((response) => {
      const genres = response.data;
      let i = 0;
      let genresArray = [];

      for (const key in genres) {
        genresArray.push({ key: i++, value: key });
      }
      setGenres(genresArray);
    });
    api.get("/authors").then((response) => {
      const authors = response.data;
      let i = 0;
      let authorsArray = [];

      for (const key in authors) {
        authorsArray.push({ key: i++, value: key });
      }
      setAuthors(authorsArray);
    });
    api.get("/publishers").then((response) => {
      const publishers = response.data;
      let i = 0;
      let publishersArray = [];

      for (const key in publishers) {
        publishersArray.push({ key: i++, value: key });
      }
      setPublishers(publishersArray);
    });
  };

  const newRow = (row) => {
    const body = {
      title: row.title,
      genre: row.genre,
      author: row.author,
      publisher: row.publisher,
      location: row.location,
      comment: row.comment,
      // description,
      // year,
      // pages,
    };
    api
      .post("/book", body)
      .then(() => {
        enqueueSnackbar(t("books-add-success"), {
          variant: "success",
        });
        getAll();
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          enqueueSnackbar(t("books-add-error-permission-denied"), {
            variant: "error",
          });
        } else if (error?.response?.status === 400) {
          enqueueSnackbar(error?.response?.data?.error, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("books-add-error"), {
            variant: "error",
          });
        }
      });
  };

  const updateRow = ({ exitEditingMode, row }) => {
    const body = {
      title:
        values?.title === row._valuesCache.title
          ? row._valuesCache.title
          : values?.title,
      genre:
        values?.genre === row._valuesCache.genre
          ? row._valuesCache.genre
          : values?.genre,
      author:
        values?.author === row._valuesCache.author
          ? row._valuesCache.author
          : values?.author,
      publisher:
        values?.publisher === row._valuesCache.publisher
          ? row._valuesCache.publisher
          : values?.publisher,
      location:
        values?.location === row._valuesCache.location
          ? row._valuesCache.location
          : values?.location,
      comment:
        values?.comment === row._valuesCache.comment
          ? row._valuesCache.comment
          : values?.comment === ""
            ? false
            : values?.comment,
    };
    api
      .put(`/book/${row.original.id}`, body)
      .then(() => {
        enqueueSnackbar(t("books-edit-success"), {
          variant: "success",
        });
        getAll();
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          enqueueSnackbar(t("books-edit-error-permission-denied"), {
            variant: "error",
          });
        }
        if (error.response?.data?.error === "jwt expired") {
          enqueueSnackbar(t("session-expired"), { variant: "error" });
        }
        enqueueSnackbar(t("books-edit-error"), {
          variant: "error",
        });
      });
    exitEditingMode();
  };

  const deleteRow = (row) => {
    api
      .delete(`/book/${row.original.id}`)
      .then(() => {
        enqueueSnackbar(t("books-delete-success"), {
          variant: "success",
        });
        getAll();
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          enqueueSnackbar(t("books-delete-error-permission-denied"), {
            variant: "error",
          });
        }
        if (error.response?.data?.error === "jwt expired") {
          enqueueSnackbar(t("session-expired"), { variant: "error" });
        }
        enqueueSnackbar(t("books-delete-error"), {
          variant: "error",
        });
      });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    state: { isLoading: loading },
    positionActionsColumn: "last",
    enableColumnOrdering: true,
    initialState: {
      sorting: [
        {
          id: "id",
          desc: true,
        },
      ],
    },
    localization:
      i18n.language === "en" ? MRT_Localization_EN : MRT_Localization_ES,
    enableGlobalFilter: true,
    editDisplayMode: "modal",
    enableEditing: true,
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: theme.palette.stripedrow,
        },
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 50,
      },
    },
    onEditingRowSave: updateRow,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip arrow placement="left" title={t("tables-edit")}>
          <IconButton
            onClick={() => {
              cleanAutocompletes();
              setValues(row.original);
              table.setEditingRow(row);
            }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <>
          {user?.role === "admin" ? (
            <Tooltip arrow placement="right" title={t("tables-delete")}>
              <IconButton sx={{ color: "red" }} onClick={() => deleteRow(row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Button
        className="mrt-create-new-row-button"
        id="mrt-create-new-row-button"
        onClick={() => setCreateModalOpen(true)}
        variant="contained">
        {t("tables-create-new-row")}
      </Button>
    ),
    renderEditRowDialogContent: ({ table, row }) => (
      <>
        <DialogTitle textAlign="center">{t("books-edit-title")}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
                paddingTop: ".5rem",
              }}>
              {columns.map((column) => (
                <>
                  {column.accessorKey === "id" ? (
                    <> </>
                  ) : column.accessorKey === "genre" ? (
                    <>
                      <FormControl fullWidth>
                        <Autocomplete
                          freeSolo
                          autoSelect
                          id="genre-autocomplete"
                          options={genres}
                          getOptionLabel={(option) => option.value}
                          renderInput={(params) => (
                            <TextField {...params} label={column.header} />
                          )}
                          value={
                            genre.initial
                              ? {
                                key: 0,
                                value: row.original[column.accessorKey],
                              }
                              : genre
                          }
                          onChange={(event, newValue) => {
                            setGenre(
                              typeof newValue === "string"
                                ? { key: 0, value: newValue }
                                : newValue === null
                                  ? { key: 0, value: "" }
                                  : newValue,
                            );
                            setValues({
                              ...values,
                              genre:
                                typeof newValue === "string"
                                  ? newValue
                                  : newValue === null
                                    ? { key: 0, value: "" }
                                    : newValue.value,
                            });
                          }}
                        />
                      </FormControl>
                    </>
                  ) : column.accessorKey === "author" ? (
                    <>
                      <FormControl fullWidth>
                        <Autocomplete
                          freeSolo
                          autoSelect
                          id="author-autocomplete"
                          options={authors}
                          getOptionLabel={(option) => option.value}
                          renderInput={(params) => (
                            <TextField {...params} label={column.header} />
                          )}
                          value={
                            author.initial
                              ? {
                                key: 0,
                                value: row.original[column.accessorKey],
                              }
                              : author
                          }
                          onChange={(event, newValue) => {
                            setAuthor(
                              typeof newValue === "string"
                                ? { key: 0, value: newValue }
                                : newValue === null
                                  ? { key: 0, value: "" }
                                  : newValue,
                            );
                            setValues({
                              ...values,
                              author:
                                typeof newValue === "string"
                                  ? newValue
                                  : newValue === null
                                    ? { key: 0, value: "" }
                                    : newValue.value,
                            });
                          }}
                        />
                      </FormControl>
                    </>
                  ) : column.accessorKey === "publisher" ? (
                    <>
                      <FormControl fullWidth>
                        <Autocomplete
                          freeSolo
                          autoSelect
                          id="publisher-autocomplete"
                          options={publishers}
                          getOptionLabel={(option) => option.value}
                          renderInput={(params) => (
                            <TextField {...params} label={column.header} />
                          )}
                          value={
                            publisher.initial
                              ? {
                                key: 0,
                                value: row.original[column.accessorKey],
                              }
                              : publisher
                          }
                          onChange={(event, newValue) => {
                            setPublisher(
                              typeof newValue === "string"
                                ? { key: 0, value: newValue }
                                : newValue === null
                                  ? { key: 0, value: "" }
                                  : newValue,
                            );
                            setValues({
                              ...values,
                              publisher:
                                typeof newValue === "string"
                                  ? newValue
                                  : newValue === null
                                    ? { key: 0, value: "" }
                                    : newValue.value,
                            });
                          }}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <TextField
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      defaultValue={row.original[column.accessorKey]}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  )}
                </>
              ))}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }}>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
  });

  return (
    <Grid item xs={13}>
      <div>
        <MaterialReactTable table={table} />
      </div>
      <CreateNewRowModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={(values) => {
          newRow(values);
          setCreateModalOpen(false);
        }}
        tittle={t("books-add-book")}
        genres={genres}
        authors={authors}
        publishers={publishers}
      />
    </Grid>
  );
};

export default Books;
