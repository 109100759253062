import React from "react";
import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <p>{t("dashboard-welcome-msg")}</p>
      </Paper>
    </Grid>
  );
};

export default Dashboard;
