import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  Tooltip,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Stack,
} from "@mui/material";

import api from "../../utils/api";

const roles = ["admin", "guest", "user"];

const CreateNewRowModal = ({ open, columns, onClose, onSubmit, tittle }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {}),
  );
  const [role, setRole] = useState("user");

  const handleSubmit = () => {
    values.role = role;
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{tittle ?? "Create New Row"}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              paddingTop: ".5rem",
            }}>
            {columns.map((column) => (
              <>
                {column.accessorKey === "role" ? (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="simple-select-label">
                        {column.header}
                      </InputLabel>
                      <Select
                        labelId="simple-select-label"
                        id="simple-select"
                        label={column.header}
                        value={role}
                        onChange={(e) => setRole(e.target.value)}>
                        {roles.map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <TextField
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                )}
              </>
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>{t("tables-cancel")}</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          {t("tables-save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Users = (themeProp) => {
  const [loading, setLoading] = useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const theme = themeProp.theme;
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user?.user);

  const columns = useMemo(
    () => [
      {
        header: `${t("tables-username")}`,
        accessorKey: "username",
        size: 20,
        enableEditing: false,
      },
      {
        header: `${t("tables-email")}`,
        accessorKey: "email",
        size: 30,
        enableEditing: false,
      },
      {
        header: `${t("tables-role")}`,
        accessorKey: "role",
        editVariant: "select",
        editSelectOptions: roles,
        size: 30,
      },
      {
        header: `${t("tables-created-at")}`,
        accessorKey: "createdAt",
        size: 30,
        enableEditing: false,
      },
      {
        header: `${t("tables-updated-at")}`,
        accessorKey: "updatedAt",
        size: 30,
        enableEditing: false,
      },
    ],
    [t],
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllUsers = () => {
    api
      .get("/users")
      .then((response) => {
        const users = response.data;
        setData(users);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === "jwt expired") {
          enqueueSnackbar(t("session-expired"), { variant: "error" });
        }
        enqueueSnackbar(t("users-get-error"), { variant: "error" });
      });
  };

  const newUser = (row) => {
    const body = {
      username: row.username,
      email: row.email,
      password: row.password,
      role: row.role,
    };
    api
      .post("/user", body)
      .then(() => {
        enqueueSnackbar(t("users-add-success"), {
          variant: "success",
        });
        getAllUsers();
      })
      .catch((error) => {
        enqueueSnackbar(t(error?.response?.data?.error), {
          variant: "error",
        });
      });
  };

  const updateUser = ({ exitEditingMode, row }) => {
    const body = {
      // username: row.values.username,
      // email: row.values.email,
      role: row._valuesCache.role,
    };
    api
      .put(`/user/${row.original._id}`, body)
      .then(() => {
        enqueueSnackbar(t("users-edit-success"), {
          variant: "success",
        });
        getAllUsers();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.expired
        ) {
          enqueueSnackbar(t("session-expired"), { variant: "error" });
        }
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
    exitEditingMode();
  };

  const deleteUser = (row) => {
    const body = {
      _id: row.original._id,
    };
    api
      .delete(`/user/${row.original._id}`, body)
      .then(() => {
        enqueueSnackbar(t("users-delete-success"), {
          variant: "success",
        });
        getAllUsers();
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      sorting: [
        {
          id: "username",
          desc: false,
        },
      ],
    },
    sx: {
      "& tr:nth-of-type(odd) > td": {
        backgroundColor: "#f5f5f5",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 50,
      },
    },
    localization:
      i18n.language === "en" ? MRT_Localization_EN : MRT_Localization_ES,
    state: { isLoading: loading },
    editingMode: "modal",
    enableColumnOrdering: true,
    enableEditing: true,
    enableGlobalFilter: true,
    enableSorting: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip arrow placement="left" title={t("tables-edit")}>
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <>
          {user?.role === "admin" ? (
            <Tooltip arrow placement="right" title={t("tables-delete")}>
              <IconButton sx={{ color: "red" }} onClick={() => deleteUser(row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Button
        className="mrt-create-new-user-button"
        id="mrt-create-new-user-button"
        onClick={() => setCreateModalOpen(true)}
        variant="contained">
        {t("tables-create-new-row")}
      </Button>
    ),
    onEditingRowSave: updateUser,
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: theme.palette.stripedrow,
        },
      },
    },
  });

  return (
    <Grid item xs={12}>
      <div>
        <MaterialReactTable table={table} />
      </div>
      <CreateNewRowModal
        // Custom columns on add new row
        columns={[
          {
            accessorKey: "username",
            enableEditing: true,
            header: `${t("tables-username")}`,
            id: "username",
          },
          {
            accessorKey: "email",
            enableEditing: true,
            header: `${t("tables-email")}`,
            id: "email",
          },
          {
            accessorKey: "password",
            enableEditing: true,
            header: `${t("tables-password")}`,
            id: "password",
          },
          {
            accessorKey: "role",
            enableEditing: true,
            header: `${t("tables-role")}`,
            id: "role",
          },
        ]}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={(values) => {
          newUser(values);
          setCreateModalOpen(false);
        }}
        tittle={t("users-add-user")}
      />
    </Grid>
  );
};

export default Users;
