import React, { useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  FormControl,
  Stack,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import api from "../../utils/api";

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const isButtonDisabled = !currentPassword || !newPassword || !confirmPassword;

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(t("change-password-error-passwords-dont-match"));
    } else {
      const body = {
        currentPassword,
        newPassword,
      };
      api
        .patch("/user", body)
        .then((response) => {
          if (response.status === 200) {
            enqueueSnackbar(t("change-password-success"), {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            enqueueSnackbar(t("change-password-error-invalid-credentials"), {
              variant: "error",
            });
          }
        });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "200px", sm: "360px", md: "400px" },
              gap: "1.0rem",
              paddingTop: ".5rem",
            }}>
            <TextField
              type="password"
              label={t("change-current-password")}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
            <Divider />
            <TextField
              type="password"
              label={t("change-new-password")}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <FormHelperText
              id="password-policy"
              sx={{ whiteSpace: "pre-line" }}>
              {t("password-policy")}
            </FormHelperText>
            <TextField
              type="password"
              label={t("change-new-password-repeat")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Stack>
        </FormControl>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </form>
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "right",
        }}>
        <Button
          type="submit"
          disabled={isButtonDisabled}
          onClick={handleSubmit}
          variant="contained">
          {t("change-password")}
        </Button>
      </Box>
    </div>
  );
};

export default ChangePasswordForm;
