import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTranslation } from "react-i18next";

import { login } from "../../utils/auth";
import loginBackground from "../../assets/login-bg-wide.png";
import { getItem, setItem, removeItem } from "../../utils/storage";
import { Copyright } from "../layout/Copyright.component";
import { VersionDisplay } from "../layout/Version.component";

import { loginTheme } from "../common/Themes.component";

export default function SignIn({ onLogin }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [theme, setTheme] = React.useState(loginTheme);
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userData = await login(email, password);
      onLogin(userData);
    } catch (error) {
      if (error.message === "Invalid username or password.") {
        setError(t("login-error-invalid-credentials"));
      } else {
        setError(error.message);
      }
    }
  };

  const handleRemember = (event) => {
    if (!event.target.checked) {
      removeItem("rememberEmail");
      setRemember(false);
    } else {
      setItem("rememberEmail", email);
      setRemember(true);
    }
  };

  React.useEffect(() => {
    const remember = getItem("rememberEmail");
    if (remember) {
      setEmail(remember);
      setRemember(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${loginBackground})`,
          backgroundRepeat: "repeat-x",
          backgroundColor: "#000000",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <CssBaseline />
        <Grid
          item
          xs={11}
          sm={8}
          md={5}
          lg={4}
          xl={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "20px",
            boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.75)",
          }}
          component={Paper}
          elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("login-title")}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label={t("login-username")}
                name="username"
                autoComplete="username"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                value={email ? email : ""}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("login-password")}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="remember"
                    checked={remember}
                    value="remember"
                    color="primary"
                    onChange={handleRemember}
                  />
                }
                label={t("login-remember-me")}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                {t("login-btn")}
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              {error && (
                <Alert variant="outlined" severity="error">
                  <AlertTitle>{t("login-error-title")}</AlertTitle>
                  {error && <p>{error}</p>}
                </Alert>
              )}
              <Copyright sx={{ mt: 5 }} />
              <VersionDisplay />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
