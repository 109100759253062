import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "es" : "en");
  };

  return (
    <IconButton
      color="inherit"
      onClick={switchLanguage}
      sx={{
        fontSize: "1.25rem",
      }}>
      <Badge color="secondary">
        {i18n.language === "en" ? "es" : "en"}
        <LanguageIcon />
      </Badge>
    </IconButton>
  );
}

export default LanguageSwitcher;
