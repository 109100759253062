import * as React from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  LibraryBooks as LibraryBooksIcon,
  People as PeopleIcon,
  BarChart as BarChartIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export function Navbar(data) {
  const { t } = useTranslation();
  const user = data.user;
  return (
    <List component="nav">
      <ListItemButton component={Link} to={"/"}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t("navbar-dashboard")} />
      </ListItemButton>
      <ListItemButton component={Link} to={"/books"}>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary={t("navbar-books")} />
      </ListItemButton>
      {user?.role === "admin" && (
        <ListItemButton component={Link} to={"/users"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={t("navbar-users")} />
        </ListItemButton>
      )}
      <ListItemButton component={Link} to={"/stats"}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={t("navbar-statistics")} />
      </ListItemButton>
    </List>
  );
}
