import React from "react";
import { Grid, Paper } from "@mui/material";

const Stats = () => {
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <p>stats...!</p>
      </Paper>
    </Grid>
  );
};

export default Stats;
