import api from "./api";
import { getItem, setItem, removeItem } from "./storage";

export const TOKEN_KEY = "token";

export const isAuthenticated = () => {
  const token = getItem(TOKEN_KEY);
  return !!token; // Return true if the token exists, false otherwise
};

export const login = async (email, password) => {
  try {
    const response = await api.post("/login", { email, password });
    const { token, userData } = response.data;
    setItem(TOKEN_KEY, token);
    return userData;
  } catch (error) {
    throw new Error("Invalid username or password.");
  }
};

export const logout = () => {
  removeItem(TOKEN_KEY);
};
