import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setUser, clearUser } from "../../utils/reducers/userReducer";
import PrivateRoute from "./PrivateRoute.component";
import Login from "../auth/Login.component";
import Base from "../layout/Base.component";
import Dashboard from "../pages/Dashboard.component";
import Books from "../pages/Books.component";
import Users from "../pages/Users.component";
import Stats from "../pages/Stats.component";
import { useTranslation } from "react-i18next";

import { isAuthenticated, logout } from "../../utils/auth";

function AppRoutes() {
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Check if the user is already authenticated
    const checkAuthentication = async () => {
      const isAuthenticatedUser = await isAuthenticated();
      setAuthenticated(isAuthenticatedUser);
    };

    checkAuthentication();
  }, []);

  const handleLogin = (data) => {
    dispatch(setUser(data));
    setAuthenticated(true);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    setAuthenticated(false);
    logout();
    navigate("/login");
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          authenticated ? (
            <Navigate to="/" />
          ) : (
            <Login
              authenticated={authenticated}
              onLogin={handleLogin}
              onLogout={handleLogout}
            />
          )
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Base onLogout={handleLogout} title={t("navbar-dashboard")}>
              <Dashboard />
            </Base>
          </PrivateRoute>
        }
      />
      <Route
        path="/books"
        element={
          <PrivateRoute>
            <Base onLogout={handleLogout} title={t("navbar-books")}>
              <Books />
            </Base>
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Base onLogout={handleLogout} title={t("navbar-users")}>
              <Users />
            </Base>
          </PrivateRoute>
        }
      />
      <Route
        path="/stats"
        element={
          <PrivateRoute>
            <Base onLogout={handleLogout} title={t("navbar-statistics")}>
              <Stats />
            </Base>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
