import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e0a15a",
    },
    // divider: "#694232",
    secondary: {
      main: "#e0a15a",
    },
    background: {
      default: "#261a15",
      paper: "#2e1910",
    },
    text: {
      primary: "#f5f5f5",
      secondary: "#b3765d",
    },
    stripedrow: "#382b25",
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const loginTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e0a15a", // buttons, ascents, borders
    },
    secondary: {
      main: "#e0a15a", // circle above Sign in
    },
    background: {
      default: "#100000", // lock icon inside circle above Sign in
      paper: "#100000", // form background
    },
    text: {
      primary: "#f5f5f5", // text
      secondary: "#e6eee8", // copyright and version
    },
    error: {
      main: "#FF5722",
    },
    warning: {
      main: "#FFC107",
    },
  },
  // components: {
  //   MuiAlert: {
  //     styleOverrides: {
  //       root: {
  //         borderColor: "#99ff33",
  //         change alert icon color to the same as borderColor
  //         "& .MuiAlert-icon": {
  //           color: "#99ff33",
  //         },
  //       },
  //     },
  //   },
  // },
});

const darkThemeGrayscale = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#f48fb1",
    },
    background: {
      default: "#303030",
      paper: "#424242",
    },
    text: {
      primary: "#f5f5f5",
      secondary: "#bbbbbb",
    },
  },
});

export { darkTheme, darkThemeGrayscale, lightTheme, loginTheme };
