import React from "react";
import {
  Avatar,
  IconButton,
  Tooltip,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

const ProfileCard = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Grid container wrap="nowrap">
        <Grid item sx={{ padding: 2, marginLeft: "1rem" }}>
          <Avatar link={user?.image} sx={{ width: 100, height: 100 }} />
        </Grid>
        <Grid item style={{ gap: "0 24px" }} xs={11}>
          <Grid
            item
            xs={3}
            sx={{
              paddingLeft: 2,
              paddingTop: 2.5,
            }}>
            <Typography variant="h5" component="div">
              {user?.username}
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            alignItems={"center"}
            sx={{ paddingLeft: 2 }}
            style={{ display: "flex" }}>
            <Typography variant="subtitle2" component="div">
              ID:{" "}
              {user?.id.length > 16 ? `${user?.id.slice(0, 16)}...` : user?.id}
            </Typography>
            <Tooltip title={t("copy-to-clipboard")}>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(user ? user.id : "");
                }}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sx={{ paddingLeft: 2 }}>
            <Typography variant="subtitle2" component="div">
              email: {user?.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileCard;
