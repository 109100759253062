import React from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SettingsTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
      }}>
      <Tabs
        orientation="vertical"
        // variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          minWidth: { xs: "100px" },
        }}>
        {tabs.map((tab) => (
          <Tab key={tab.key} label={tab.name} {...a11yProps(tab.key)} />
        ))}
      </Tabs>
      {tabs.map((tab) => (
        <TabPanel key={tab.key} value={value} index={tab.key}>
          <tab.component />
        </TabPanel>
      ))}
    </Box>
  );
};

export default SettingsTabs;
